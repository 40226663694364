
.sale {
	position: relative;
	display: inline-block;
	background: rgb(253, 73, 73);
	color: rgb(255, 255, 255);
	height: 1.4rem;
	width: 1.4rem;
	text-align: center;
	vertical-align: middle;
	// line-height: 0.7rem;
    font-size: medium;
    font-weight: bold;
    padding-top: 1px;
	/* margin: 45vh 45vw; */
    margin-left: 10px;
	transform: rotate(-20deg);
	// animation: beat 1s ease infinite alternate;
	&:before,
	&:after {
		content:"";
		position: absolute;
		background: inherit;
		height: inherit;
		width: inherit;
		top: 0;
		left: 0;
		z-index: -1;
		transform: rotate(30deg);
	}
	&:after {
		transform: rotate(60deg);
	}
}

.newflash {
	position: relative;
	display: inline-block;
	background: rgb(253, 73, 73);
	color: rgb(255, 255, 255);
	height: 1.4rem;
	width: 1.4rem;
	text-align: center;
	vertical-align: middle;
	line-height: 1.2rem;
    // font-size: medium;
    font-weight: bold;
	/* margin: 45vh 45vw; */
    margin-left: -10px;
    margin-right: 20px;
	transform: rotate(-20deg);
	// animation: beat 1s ease infinite alternate;
	&:before,
	&:after {
		content:"";
		position: absolute;
		background: inherit;
		height: inherit;
		width: inherit;
		top: 0;
		left: 0;
		z-index: -1;
		transform: rotate(30deg);
	}
	&:after {
		transform: rotate(60deg);
	}
}

@keyframes beat {
	from {	transform: rotate(-20deg) scale(1); }
	to {	transform: rotate(-20deg) scale(1.1); }
}