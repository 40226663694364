.signTitle{
    background: #D9FFA2 !important;
    font-family: Roboto;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 16px;
text-align: center;
color: #2F323A;
padding: 10px !important;
}

.Content{
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
align-items: center;
text-align: center;
color: #495769;
padding: 10px;
margin: 30px 0 0 0;
}

.justify-center{
    justify-content: center;
}

.hydrated .facebook {
    visibility: hidden !important;
}

.sigin amplify-section{
    display: none !important;
    background-color: #495769 !important;
}
.btnLabel{
    font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;
text-align: center;
color: #72869F;
cursor: pointer;
}

.labelContent{
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
text-align: center;
color: #72869F;
}

.customui{
    width: 440px !important;
}
.customui form{
    width: 100% !important;
}
.customui form .MuiFormControl-root{
    width: 70% !important;
}
.customui .MuiInputBase-input{
    margin: 16px 0 0 !important;
    
}

.customui .login{
    background: #1CA8DD;
    color: #ffffff;
    width: 70% !important;
    margin: 16px 0 !important;
    border-radius: 0 !important;
}

.wid70{
    margin: auto !important;
    width: 70% !important;
}

.socialBtn, .signBtn,.socialBtn .form-field{
    color: #1CA8DD !important;
    border: 1px solid #1CA8DD !important;
    border-radius: 0 !important;
    margin-left: 2px;
    margin-right: 2px;
    /* width: 30% !important; */
}

.btn-rt-m{
 margin: 0 auto 0 0!important;
}

.btn-lt-m{
    margin: 0 0 0 auto!important;
}

.customui .resendBtn{
    width: 70% !important;
    margin: 16px 0 !important;
    border-radius: 0 !important;
}

.customui .Content{
    padding: 10px 10px 30px !important;   
}

.error-label{
    color: red !important;
}

@media only screen and (max-width: 500px) and (min-width: 310px)    {

.customui{
    width: 100% !important;
}

.socialBtn{
width: 100% !important;
margin-bottom: 16px !important;
}

.signInDialog .MuiDialog-container .MuiPaper-root{
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.dispBtninRow{
    margin: 4px 0!important;
    width: 100% !important;
}
.displayFlexBlock{
    display: block !important;
}

}
.text-alignleft{
    text-align-last: left;
}
.forgotTxt{
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 12px;
color: #71C001;
margin-top: 10px !important;
}

.customui .MuiButton-root{
    text-transform:capitalize !important;
}
.customui .MuiButton-root:hover{
    background: #1ca8dd94;
    color: #000;
}

.clsBtn{
    height: fit-content !important;
    width: fit-content !important;
    float: right;
    padding: 0 !important;
}


.signInDialog .MuiSnackbar-root .MuiPaper-root {
    position: static !important;
} 

.displayFlexBlock{
    display: flex;
}

.dispBtnBlock{
    width: 100% !important;
    margin: 6px 0 !important;
}
.optin{
    width: 70% !important;
    margin: auto !important;
    display: flex;
    justify-content: start;
    padding-top: 5px;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
}

.optBtn{
    color: #1CA8DD;
    font-size: 12px;
    padding: 4px;
}
.formControl{
    display:flex;
    flex-direction: column;
}
.formSelect .MuiSelect-select:focus{
    background-color: transparent !important;
}
.formControlLabel{
    display:flex;
    width:480px;
    flex-direction: row;
    justify-content: space-between;
    padding:18px 0px 18px 0px;
    color:#2F3D5B;
    font-size:14px;
    font-family: Lato;
    font-style: normal;
}
.formControlLabel .MuiTypography-body1{
    font-size:14px !important;
}
 .form_container .MuiSwitch-root {
    position: absolute;
    right: 0;
}
.formControlLabel .MuiSwitch-root .label{
    text-transform: lowercase;
}
.studentsTools{
    display:flex;
    flex-direction: row;
    font-size: 14px;
}
.customiseTools{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:10px 0px;
}
.footer{
    display:flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
}
.useremail{
    color:#2F3D5B
}
.resetSettings{
    color:#55677D
}
.ndCloseIcon{
    margin:0px 15px;
}
.formSelect .MuiOutlinedInput-input {
    padding: 14px 20px !important;
}
.formSelect .MuiSelect-iconOutlined {
    right:20px;
    top:13px;
}
.studentsTools .MuiButton-iconSizeMedium > *:first-child {
    font-size: 30px;
    color: #612AFF;
    margin-right:11px;
}

.customizeTools .MuiButton-contained{
    box-shadow: none !important;
}
.form_container label, button, .button{
    text-transform: none;
}
.studentsTools  button, .button{
    text-transform: none;
}
.formControlLabel .MuiSwitch-colorSecondary.Mui-checked {
    color:#3174F5;
}
.formControlLabel .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #79a6fa;
}
.lockSection .MuiSwitch-colorSecondary.Mui-checked {
    color:#3174F5;
}
.lockSection .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #79a6fa;
}
.itemList .MuiTypography-body2 {
    font-size: 14px !important;
}

.form_container .MuiSwitch-colorSecondary.Mui-checked, .lblswt .MuiSwitch-colorSecondary.Mui-checked  {
    color:#3174F5;
}
.form_container .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track, .lblswt .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track  {
    background-color: #79a6fa;
}
.lblswt{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
 
.lblswt .MuiTypography-body1{
    text-transform: capitalize;
}
