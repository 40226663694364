.newContent{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #00233D;
}
.hideInNewDesign{
    display: none;
}


.newClsAuthD{
    width: 540px !important;
}

.newClsAuthD form .MuiFormControl-root{
    width: 100% !important;
}

.newClsAuthD .login, .newClsAuthD .optin{
    width: 100% !important;
} 
.logoContainer{
    margin-top: 24px;
    display: flex;
}

.newClsAuthD .Content{
    margin: 24px 0 !important;
    padding: 0 !important;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #00233D;
}

.posClose{
    position: absolute;
    right: 0;
}

.newClsAuthD .MuiInput-underline:after, .newClsAuthD .MuiInput-underline:before{
    border: none !important;
}

.newClsAuthD input{
    border: 1px solid;
    border-radius: 50px;
    padding: 10px;
}

.newClsAuthD label + .MuiInput-formControl{
    margin-top: 4px !important;
}

.newClsAuthD label{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #00233D;
    text-transform: capitalize;
}

.newClsAuthD .forgotTxt, .newClsAuthD .btnLabel{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #3174F5;
}
.newClsAuthD .login, .newClsAuthD .signBtn, .sigOutBtn{
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
    height: 40px !important;
    background: #3174F5 !important;
    border: 1px solid #1F68F4 !important;
    box-sizing: border-box !important;
    border-radius: 999px !important;
    color: white !important;
    margin: 8px 0 !important;
    text-transform: uppercase !important;
    font-family: Lato !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: bold !important;
}

.newClsAuthD .login .MuiButton-label{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
    font-feature-settings: 'ss04' on !important;
    color: #FFFFFF !important;
}
.newClsAuthD .labelContent{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
    color: #00233D;
}
.newClsAuthD .socialBtn{
        background: #FFFFFF !important;
        border: 1px solid #D1D5DB !important;
        box-sizing: border-box !important;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
        border-radius: 6px !important;
        width: 92.33px;
        height: 32px;
        flex-grow: 1;
}

.newClsAuthD .joinContent{
    padding: 0;
    margin:  4px !important;
}
.newClsAuthD .dividerContent{
    margin: 20px 0;
}
.newClsAuthD .btnLabel{
    margin-left: 4px;
}

.lato12-info{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #6B7280 !important; 
}

.termPriv{
    padding: 0 !important;
    display: flex;
    justify-content: center;
}

.newClsAuthD .optBtn{
    font-family: Lato !important;
    font-style: normal !important; 
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    color: #00233D !important;
}
.newClsAuthD .optin{
    margin: 0 0 0 5px !important;
}

.btnPanel{
    justify-content: space-between;
}
.btnPanel button{
    width: 45% !important;
    color: #FFFFFF !important;
}

.newClsAuthD .MuiButton-root.Mui-disabled{
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.26) !important;
    border: none !important;
}

.settingHeader .newContent{
    padding: 0 !important;
    margin: 24px 0;
}
.header {
    display: flex;
    flex-direction: row;
}

.sigOutBtn{
    width: 320px;
    margin: 8px auto !important;
    padding: 8px !important;
}