  .MSTeamsApp {
    text-align: center;
  }
  h1 {
    color: gray;
  }
  .MSTeamsApp.h2 {
    font-size: .8em;
  }
  .MSTeamsApp.PropName {
    font-weight: bold;
    color: #6264A7;
  }
  .Logo {
    font-size: 45pt;
    color: #6264A7;
  }
  .Error {
    color: red;
  }