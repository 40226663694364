.headerDots {
    width:225px;
    height:7px;
    display: flex;
    justify-content: space-around;
    background-color: #E2E6E9;
}
.headerDots:hover{
    cursor:"pointer"
}
.participantHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    width: 225px;
    height: 50px;
    font-size: 16px;
    color: #40537B;
}
.pagenumber{
    position:relative;
}

.inputNumber .MuiInput-root{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #E0E5E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgb(0 35 61 / 4%);
    border-radius: 4px;
    width: 32px;
    height: 24px;
}
.buttonName {
    margin:6px 8px;
}
.buttonName .MuiListItemAvatar-root{
    min-width: 35px ;
}
.listingItem {
    margin-left: 4px;
    width: 210px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.listingItem .MuiListItem-root{
    padding-top:0px;
    padding-bottom:0px;
}
.popover .MuiPopover-paper{
    position: absolute;
    width: 180px;
    height: 140px;
}
.cardBoxIn{
    width: 180px;
    min-height: 40px;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;
}
.cardBoxIn .MuiTypography-body1 {
    font-size: 16px;
    color:#40537B;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
}
.cardBoxIn .MuiListItemIcon-root {
    min-width: 40px;
}

.notification .MuiListItemIcon-root {
    min-width: 40px;
}
