.MSTeamsSignInBg {
    height: 100%;
    width: 100%;
}

.MSTeamsSignInPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}

.PlatformDescriptionTitleText {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    
    text-align: center;
    
    color: #526A9E;
}

.PlatformDescriptionText {
    width: 443px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */
    
    text-align: center;
}

.SignInButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
/* identical to box height, or 14px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-feature-settings: 'ss04' on;
    /* position: absolute; */
    width: 180px;
    height: 48px;
    /* left: 567px;
    top: 476px; */

    /* background: linear-gradient(180deg, #FFFFFF 0%, #F4F5F6 100%); */
    border: 1px solid #C8CEDA;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 35, 61, 0.04);
    border-radius: 999px;
    margin: 8px;
}

.cfgBgThemeDark {
    background-color: #292929;
    color: #F4F5F6;
}

.cfgBgThemeLight {
    background-color: inherit;
    color: #526A9E;
}

.bgThemeDark {
    background-color: #1F1F1F;
    color: #F4F5F6;
}

.bgThemeLight {
    background-color: inherit;
    color: #526A9E;
}

.btnThemeDark {
    background: linear-gradient(180deg, #000000 0%, #888888 100%);
}

.btnThemeLight {
    background: linear-gradient(180deg, #FFFFFF 0%, #F4F5F6 100%);
}

.SignInButton:hover {
    background: #3174F5;
    color: #FFFFFF;
}

.wbc_banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}