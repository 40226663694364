.threeDots {
    color: #7D8FB6;
    position: relative;
    top: -6px;
    width: 100% !important;
}
.threeDots:hover {
    color: #3174F5;
    cursor: move;
}
.coOrdinateFormControl {
    font-family: Lato;
    color: #2F3D5B;
    display: flex;
    flex-direction: column;
}
.coOrdinateFormControl .MuiTypography-body1 {
    font-size: 14px;
    text-transform: capitalize;
}
.coOrdinateFormControl .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3174f5;
}
.inputTextFiled {
    width: 315px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E0E5E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 35, 61, 0.04);
    border-radius: 999px;
    padding: 0px 25px;
}
.closeIconItem {
    background: #E9EBF0;
    color: rgb(47, 61, 91);
    cursor: pointer;
    border-radius: 50%;
    position : absolute;
    top: 18px;
    right: 20px;
    z-index: 100;
}
.headerPart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    width: 200px;
    right:  10px;
}
.headerPart .MuiOutlinedInput-input {
    padding: 10.5px 14px;
}
.headerPart .MuiSelect-select:focus {
    background: transparent !important;
}
.headerPart .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3174F5;
}
.inputname {
    padding: 8px 20px;
    background: #FFFFFF;
    border: 1px solid #E0E5E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 35, 61, 0.04);
    border-radius: 999px;
    margin:"8px 0px !important";
}
.positioning {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200px;
    flex-direction: row-reverse;
    padding: 8px 24px 24px !important;
}
.formControlAlignment {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    color:#2F3D5B;
    font-size:14px;
    font-family: Lato;
    font-style: normal;
    text-transform: capitalize;
}
.formControlAlignment .MuiSwitch-colorSecondary.Mui-checked {
    color: #3174f5;
}
.formControlAlignment .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #79a6fa;
}
.formControlAlignment .MuiTypography-body1 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #2F3D5B;
    text-transform: capitalize;
}
.dialogMessage {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 100% !important;
    color: #2F3D5B !important;
}
.dialogActionButtons {
    display: flex !important;
    padding: 8px 24px 24px !important;
    align-items: center !important;
    justify-content: space-evenly !important;
}
.textFiledInput .MuiOutlinedInput-root {
    border-radius: 999px;
}
.textFiledInput .MuiOutlinedInput-input {
    padding: 14px 14px
}
.textFiledInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3174F5 !important;
}
.listItemAlignment .MuiInput-underline:before{
    border-bottom: none !important;
}
.listItemAlignment .MuiInput-underline:after{
    border-bottom: none !important;
}
.listItemAlignment .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
}
.listItemAlignment .MuiInputBase-root {
    border:1px solid !important;
    border-radius: 999px !important;
}
.listItemAlignment .MuiSvgIcon-root {
   margin: 3px 10px !important;
}
.radioButtonStyle .MuiRadio-colorSecondary.Mui-checked  {
    color: #3174f5;
}
.radioButtonStyle .MuiTypography-body1 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #2F3D5B;
    text-transform: capitalize;
}
.dialogContentText .MuiFormHelperText-root {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    color: #2F3D5B;
    text-transform: capitalize;
    margin:10px 5px;
}
.createButton {
    color: #FFFFFF !important;
    font-size: 16px !important;
    cursor: pointer !important;
    border-radius: 999px !important;
    font-family: Lato !important;
    font-weight: normal !important;
    width: 100px !important;
    padding: 6px 12px !important;
    text-transform: capitalize !important;
    letter-spacing: -0.078px !important;
}
.createButton:hover {
    color: #2F3D5B  !important;
}
.notificationPopUp {
    position: absolute !important;
    border: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    right: 80px;
    top: 75px;
    width: fit-content !important;
}
.formControlLabelPart {
    display: flex !important;
    width: 350px !important;
    margin-left: 5px !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.addAnswer {
    width: 400px !important;
    font-family: Lato !important;
    color: #1E2639  !important;
}
.contextStyle {
    font-family: Lato !important;
    color: #2F3D5B  !important;
    font-size: 14px !important;
}
.mainHeader {
    font-family: Lato !important;
    color: #1E2639  !important;
}
.listItemStyle .MuiListItemAvatar-root {
    min-width: 40px !important;
} 
.listItemStyle .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3174f5;
}
.chatHeader {
    font-family: Lato !important;
    color: #2F3D5B  !important;
    font-size: 16px !important;
    font-weight: bold;
}
.closingIcon {
    background-color: #E9EBF0 !important;
    color: rgb(47, 61, 91) !important;
    cursor: pointer !important;
    border-radius: 50% !important;
    position: absolute !important;
    right: 22px !important;
    top: 20px !important;
}
.toggleAllButton {
    color: #FFFFFF !important;
    font-size: 16px !important;
    cursor: pointer !important;
    border-radius: 999px !important;
    font-family: Lato !important;
    font-weight: normal !important;
    width: 250px !important;
    padding: 6px 12px !important;
    text-transform: capitalize !important;
    letter-spacing: -0.078px !important;
}
.toggleAllButton:hover {
    color: #2F3D5B  !important;
}
.dialogAcionButton {
    padding: 8px 24px 20px !important;
}
.takePhotoButton {
    color: #FFFFFF !important;
    font-size: 16px !important;
    cursor: pointer !important;
    border-radius: 999px !important;
    font-family: Lato !important;
    font-weight: normal !important;
    width: 150px !important;
    padding: 8px 12px !important;
    text-transform: capitalize !important;
    letter-spacing: -0.078px !important;
}
.takePhotoButton:hover {
    color: #2F3D5B  !important;
}