.kiddieFrameOuter {
    position: fixed;
    top: 0;
    left: 0;
    border: 16px solid;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
}

.kiddieFrameInner {
    position: fixed;
    top: 8px;
    left: 8px;
    border: 10px solid white;
    border-radius: 24px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    z-index: 101;
    pointer-events: none;
}

.kfLeft {
    top: 0;
    left: 0;
    position: fixed;
    width: 18px;
    height: 100%;
    z-index: 1600;
    /* background-image: url(/animals/001-ant.png); */
    background-size: 18px;
    background-repeat: repeat-y;
}
.kfTop {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 18px;
    z-index: 1600;
    /* background-image: url(/animals/001-ant.png); */
    background-size: 18px;
    background-repeat: repeat-x;

}
.kfBottom {
    left: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 18px;
    z-index: 1600;
    /* background-image: url(/animals/001-ant.png); */
    background-size: 18px;
    background-repeat: repeat-x;
}
.kfRight {
    top: 0;
    right: 0;
    position: fixed;
    width: 18px;
    height: 100%;
    z-index: 1600;
    /* background-image: url(/animals/001-ant.png); */
    background-size: 18px;
    background-repeat: repeat-y;
}