@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

/* body {
  background: #F2F2F2;
  padding: 0;
  margin: 0;
} */

#bgimg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 95%;
    width: 100%;
    transform: scale(1.2);
    background-image: url('/DSC_bg.png');
    opacity: 50%;
}

#paycycle {
    position: relative;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
}

#price {
  text-align: center;
}

.plan {
  display: inline-block;
  margin: 10px 1%;
  font-family: 'Poppins', Arial, sans-serif;
}

.plan-inner {
  background: #fff;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  border: 1px solid #c7c7c7;
  position:relative;
}

.entry-title {
  background: #53CFE9;
  height: 140px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.entry-title>h3 {
  background: #20BADA;
  font-size: 20px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

@-webkit-keyframes pop-in {
    0% { opacity: 0; -webkit-transform: scale(0.5); }
    100% { opacity: 1; -webkit-transform: scale(1); }
    }
    @-moz-keyframes pop-in {
    0% { opacity: 0; -moz-transform: scale(0.5); }
    100% { opacity: 1; -moz-transform: scale(1); }
    }
    @keyframes pop-in {
    0% { opacity: 0; transform: scale(0.5); }
    100% { opacity: 1; transform: scale(1); }
}

.entry-title .price {
  position: absolute;
  bottom: -25px;
  background: #20BADA;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 80px;
  font-size: 28px;
  font-weight: 700;
  -webkit-animation:pop-in 0.5s;
  -moz-animation:pop-in 0.5s;
  -ms-animation:pop-in 0.5s;
}

.price span {
  position: absolute;
  font-size: 9px;
  bottom: -10px;
  left: 30px;
  font-weight: 400;
}

.entry-content {
  color: #323232;
}

.entry-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  border-bottom: 1px solid #E5E5E5;
  padding: 10px 0;
}

.entry-content li:last-child {
  border: none;
}

.btn {
  padding: 3em 0;
  text-align: center;
  position: relative;
}

.btn a {
  /* background: #323232; */
  background: #727272;
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  border-radius: 4px;
}
.hot {
    position: absolute;
    top: -7px;
    background: #F80;
    color: #fff;
    text-transform: uppercase;
    z-index: 2;
    padding: 2px 5px;
    font-size: 9px;
    border-radius: 2px;
    right: 10px;
    font-weight: 700;
}
.current {
    position: absolute;
    top: -7px;
    background: #F80;
    color: #fff;
    text-transform: uppercase;
    z-index: 2;
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 2px;
    right: 10px;
    font-weight: 700;
}
.default .entry-title {
  background: #75DDD9;
}

.default .entry-title > h3 {
  background: #44CBC6;
}

.default .price {
  background: #44CBC6;
}

.educator .entry-title {
  background: #aad484;
}

.educator .entry-title > h3 {
  background: #7FB055;
}

.educator .price {
  background: #7FB055;
}

.educator .btn a {
    background: #7FB055;
}

.basic .entry-title {
  background: #5a94ff;
}

.basic .entry-title > h3 {
  /* background: #20BADA; */
  background: #3174F5;
}

.basic .price {
  background: #3174F5;
}

.basic .btn a {
    background: #3174F5;
}

.gold .entry-title > h3 {
    background: #FFC62F;
}

.gold .entry-title {
    background: #fdd872;
}

.gold .price {
    background: #FFC62F;
}

.gold .btn a {
    background: #FFC62F;
}

.ultimate .entry-title > h3 {
  background: #DD4B5E;
}

.ultimate .entry-title {
  background: #F75C70;
}

.ultimate .price {
  background: #DD4B5E;
}

.blueButton a {
  background: #3174F5;
}

#whypay {
    position: relative;
    text-align: center;
    color: #5c5c5c;
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 0.84rem;
    line-height: 1.1rem;
}

#username {
    color: #5c5c5c;
    font-family: 'Poppins', Arial, sans-serif;
    font-size: medium;
}

#orgContactUs {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  color: #d96758;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 0.84rem;
  line-height: 1.1rem;
  /* font-size: 0.9rem;
  line-height: 1.2rem; */
}

#howthisworks {
    position: relative;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 5rem;
    color: #5c5c5c;
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.2rem;
}

#howitworksbullets {
    position: relative;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 30px;
    padding-right: 50px;
    width: 80%;
}

#howitworksbullets ul li {
    position: relative;
    color: #5c5c5c;
    font-size: 0.72rem;
    line-height: 0.9rem;
    margin-bottom: 20px;
}

#footerButtons {
    text-align: center;
}