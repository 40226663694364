.topbg {
    height: 100vh;
    max-height: 780px;
    width: 100vw;
    background-image: url('/a11y_bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.splashtext {
    margin-top: 18%;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 120%;
    
    text-align: center;
    
    color: #002029;
}

.splashcaption {
    width: 75%;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    
    text-align: center;
    
    color: #002029;
    margin: auto;
}

.sectionpanel {
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    object-fit: contain;
    object-position: 0% 0%;
    cursor: pointer;
}

.subsectiontitle {
    margin-top: 3vh;
    margin-bottom: 3vh;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 4vmin;
    line-height: 120%;

    text-align: center;

    color: #002029;
}

.titlebar {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    top: 0px;
    z-index: 10;
    width: 100%;
}

.titlebarlogo {
    flex-grow: 1;
    margin: 20px 80px 20px 4vmin;
}

.titlebarbutton {
    flex-grow: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    font-size: 2.6vmin;
    padding: 1vw 2vw 1vw;
    cursor: pointer;
}

.startdrawing {
    background: white;
    border-radius: 999px ;
    margin-right: 3vw;
    text-align: center;
}

.featurecard {
    height: auto;
    object-fit: 'contain';
    object-position: '0% 0%';
    cursor: pointer;
}

.startteachingpanel {
    position: relative;
    height: calc(10vmax + 10vmin)/2;
    top: calc((10vmax + 10vmin)/4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 32, 41, 0.08);
    border-radius: 8px;
    padding: 3vh 5vw 3vh 5vw;
    text-align: left;
}

.startteachingpaneltext {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 2vmin;
    line-height: 120%;
    /* or 22px */
    color: #002029;
}

.panelbutton {
    background: #4076ED;
    border-radius: 999px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2vmin;
    line-height: 120%;
    /* or 22px */
    color: white;
    padding: 1vh 2vw 1vh 2vw;
    text-align: center;
    width: 18vw;
    cursor: pointer;
}


.writing_practice {
    position: relative;
    top: -90vh;
    left: 20vw;
    height: 4vh;
}

.gridstext {
    position: relative;
    top: -85vh;
    left: 20vw;
    height: 24vh;
}

.handwritingtext {
    position: relative;
    top: -80vh;
    left: 20vw;
    height: 16vh;
}

.upload_handwritten {
    position: relative;
    top: -75vh;
    left: 20vw;
    height: 21vh;
}

.copyrighttext {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8vmin;
    line-height: 100%;
    text-align: center;

    color: #808080;
}

.dmwContainer{
background-image: url('/dmwbg.svg');
}

.dmw{
    display: flex;
    flex-direction: column;
}


.wpContainer{
    background-image: url('/wpBg.svg');
}
.wpLeft{
    margin: 100px 0 50px;
}

.wpSection{
    display: flex;
    flex-direction: column;
    padding: 50px;
}
.wpSection img:first-child{
    margin: 90px auto auto;
}


@media only screen and (max-width: 1080px) {
    .dmw .featurecard:first-child{
        margin: 40px auto 40px !important;
    }
    .dmw .featurecard{
        width: 32vw;
        margin: 5px auto !important;
    }
    .wpSection{
        padding: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .dmw .featurecard+.dmw2{
        margin: 0px auto 50px !important;
    }
    .dmw .featurecard{
        width: 60vw;
        margin: 25px auto !important;
    }
    
    .dmw .featurecard:first-child {
        margin: 40px auto 10px !important;
    }
    .wpSection img{
        margin: 10px ​auto !important;
    }
    .popupHeader{
        width: 70vw !important;
    }
}


 


