:root {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    background: #F8F9FB;
    --amplify-primary-color: #1CB1C4;
    --amplify-primary-tint: purple;
    --amplify-primary-shade: #1CB1C4;
}

.logo {
    position: absolute;
    width: 87px;
    height: 23px;
    right: 5vw;
    top: 2vh;
    background: url(epiphani-white.png);
}

.iframe {
    position: absolute;
    right: 0;
    top: 30vh;
    z-index: 99;
}

.helpButton {
    /* position: absolute; */
    /* width: 70px;
    height: 18px; */
    right: 6vw;
    top: 3vh;
    padding: 6px 8px;
    border: none;
    background: #1CA8DD !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    z-index: 50;
    /* or 28px */
    /* 4883FF */
    border-radius: 2px;
    color: #FFFFFF !important;
}
.helpButton2 {
    /* position: absolute; */
    width: fit-content;
    /* 
    height: 22px; */
    /* right: 4vw; */
    /* top: 1vh; */
    /* bottom: 240px; */
    padding: 6px 8px;
    margin-top: 8px;
    margin-bottom: 4px;
    border: none;
    background: #1CA8DD !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    z-index: 50;
    /* or 28px */
    /* 4883FF */
    border-radius: 2px;
    color: #FFFFFF !important;
}
.headEpic {
    position: absolute;
    width: 794px;
    left: 5vw;
    top: 2vh;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 55px;
    /* white */
    color: #FFFFFF;
}

.connectMsg {
    position: absolute;
    width: 698px;
    height: 1rem;
    left: 5vw;
    top: 15vh;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 172.69%;
    /* or 31px */
    /* white */
    color: #FFFFFF;
}

.newEpicBoard {
    background: #71C001 !important;
    border-radius: 2px;
    color: #FFFFFF !important;
}

.arrowEB {
    position: absolute;
    right: -80px;
    top: 20px;
}
.boxes {
    position: absolute;
    left: 5vw;
    bottom: 10px;
    display:flex;
}
.brain {
    position: absolute;
    width: 270px;
    height: 209px;
    left: 0vw;
    bottom: 5px;
    /* Orange */
    background: #F4881C;
    mix-blend-mode: lighten;
    border-radius: 4px;
}

.brainText {
    position: absolute;
    width: 239px;
    height: 100px;
    left: 2vw;
    bottom: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 183.19%;
    /* or 26px */
    /* 2B3042 */
    color: #2B3042;
}

.boxIcon {
    position: absolute;
    left: 5px;
    top: 5px;
    /* 2B3042 */
}
.scroll {
    position: relative;
    overflow: auto;
    height: 100%;
  background-color: #dbe3e6 !important;
}
.all {
    overflow: visible;
    display: flex;
    flex-direction: column;

}
.whiteBox {
    position: absolute;
    width: 270px;
    height: 209px;
    left: 300px;
    bottom: 5px;
    /* Orange */
    background: #F4881C;
    mix-blend-mode: lighten;
    border-radius: 4px;
}

.whiteText {
    position: absolute;
    width: 239px;
    height: 100px;
    left: 2vw;
    bottom: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 183.19%;
    /* or 26px */
    /* 2B3042 */
    color: #2B3042;
}

.qaBox {
    position: absolute;
    width: 270px;
    height: 209px;
    left: 600px;
    bottom: 5px;
    /* Orange */
    background: #F4881C;
    mix-blend-mode: lighten;
    border-radius: 4px;
}

.qaText {
    position: absolute;
    width: 239px;
    height: 100px;
    left: 2vw;
    bottom: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 183.19%;
    /* or 26px */
    /* 2B3042 */
    color: #2B3042;
}

.link {
    color: #2B3042;
}
.rect3 {
    position: absolute;
    width: 1707.23px;
    height: 826.65px;
    left: 280px;
    top: 602.78px;
    
    background: linear-gradient(205.84deg, #72869F 3.26%, #D7E4EB 100%);
    transform: rotate(-27.14deg);
}

/* Rectangle 4 */

.rect4 {
    position: absolute;
    width: 433.14px;
    height: 13.43px;
    left: 1104.15px;
    top: 403.05px;
    background: linear-gradient(10.78deg, #72869F 38.3%, #D7E4EB 70.55%);
    mix-blend-mode: overlay;
    border-radius: 99px;
    transform: rotate(-27.14deg);
}

/* Rectangle 5 */

.rect5 {
    position: absolute;
    width: 433.14px;
    height: 13.43px;
    left: 39px;
    top: 108.58px;
    background: linear-gradient(10.78deg, #72869F 38.3%, #D7E4EB 70.55%);
    mix-blend-mode: overlay;
    border-radius: 99px;
    transform: rotate(-27.14deg);
}

/* Rectangle 6 */

.rect6 {
    position: absolute;
    width: 433.14px;
    height: 13.43px;
    left: -79px;
    top: 992.58px;
    padding: 0;
    margin: 0;
    border: 0;
    background: linear-gradient(10.78deg, #72869F 38.3%, #D7E4EB 70.55%);
    mix-blend-mode: overlay;
    border-radius: 99px;
    transform: rotate(-27.14deg);
}

.rect7 {
    position: absolute;
    width: 433.14px;
    height: 13.43px;
    left: 724px;
    top: 342.58px;
    
    background: linear-gradient(10.78deg, #72869F 38.3%, #D7E4EB 70.55%);
    mix-blend-mode: overlay;
    border-radius: 99px;
    transform: rotate(-27.14deg);
}

.title{
    /* font-family: Helvetica Now Micro; */
font-style: normal;
font-weight: bold !important;
font-size: 18px !important;
line-height: 172.69% !important;
/* text-transform: uppercase !important; */
color: #2F323A !important;
}

.drawShareColab{
    /* font-family: Helvetica Now Display; */
font-style: normal;
font-weight: bold !important;
font-size: 2.5rem !important;
line-height: 97.19% !important;
color: #2F323A !important;
word-break: break-word;
}

.drawShareColabL2{
    /* font-family: Helvetica Now Display; */
font-style: normal;
font-weight: bold !important;
font-size: 2.0rem !important;
line-height: 97.19% !important;
color: #2F323A !important;
word-break: break-word;
}

.normalText{
    /* font-family: Helvetica Now Display; */
font-style: normal !important;
font-weight: normal !important;
font-size: 18px !important;
line-height: 172.69% !important;
color: #2F323A !important;
}

.mr-top{
    margin-top: calc(100% - 93%) !important;
}

.colab-btn{
    /* font-family: Helvetica Now Display; */
font-style: normal !important;
font-weight: bold !important;
font-size: 24px !important;
line-height: 158.19% !important;
display: flex !important;
align-items: center !important;
text-align: center !important;
letter-spacing: 0.02em !important;
color: #FFFFFF !important;
text-transform: none !important;
padding: 20 20 20 20 !important;
}

.pendraw{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 75% !important;
    width: 60% !important;
    margin-top: calc(100% - 90%) !important;
}
.helpButton a,.helpButton2 a{
    text-decoration: none; 
}

.copyrt{
    position: absolute;
    bottom: 5px;
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 172.69%;
text-align: center;
color: #72869F;
}

.btn-content{
    /* font-family: Helvetica Now Display; */
font-style: normal !important;
font-weight: bold !important;
font-size: 16px !important;
line-height: 158.19% !important;
display: flex !important;
align-items: center !important;
text-align: center !important;
letter-spacing: 0.02em !important;
color: #FFFFFF !important;
text-transform: none !important;
}

.help-btns{
    position: absolute;
    top: 0;
    right: 28vh;
}