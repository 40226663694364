.Text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis ;
}


.tileBgBox{
    border-radius: 0 !important;
    border: 1px solid #D7E4EB;
    background: #ffffff !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 40px rgba(189, 194, 213, 0.15) !important;
}

.tileContainer{
    height: 134px;
}

.boardTitle{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #2B3042 !important;
}

.boardContent{
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 24px !important;
    color: #72869F !important;
}

.boardsScroll{
    /* height: 88vh !important;  */
    width: 100% !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
}

  .textContent{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #72869F;
    
  }

  .iconPosition{
    position: relative;
    top: 5px;
  }

  .boardTable .MuiTableCell-root{
      padding: 4px 10px !important;
  }

  .boardTable .MuiTableCell-head {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #C2CFD7;
  }

  .boardTable tbody td p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 133.19%;
    color: #72869F;
  }

  .sessionPaper{
    margin: 20px;
    background: #FFFFFF;
    border: 0.5px solid #D7E4EB;
    border-radius: 12px !important;
    padding-bottom: 0px;
  }

  .boardTable tbody tr:last-child td,  .boardTable tbody tr:last-child th{
border: none;
  }

@media only screen and (max-width: 800px) and (min-width: 310px) {

  .mobBoardTitle{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 152.69% !important;
    color: #72869F !important;
  }
  .boardsScroll > div:last-child{
    margin-bottom: 15vh !important;
}
}

.togglePos{
  position: relative;
    top: 50%;
}