
.newbgOrn {
    position: absolute;
    width: 94%;
    height: auto;
    left: 4%;
    top: 26%;
    z-index: 0;
}

.startDrawingButtonLast {
    display: inline-flex;
    cursor: pointer;
    padding: 12px 4vw;
    /* Periwinkle Blue */
    color: #3174F5;
    border-radius: 50px;
    /* or 26px */
    text-align: center;
    background: #FFFFFF;
    /* Inside Auto Layout */
    align-self: center;
    margin: 8px 0px;
    z-index: 100;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    width: 25vw;
    display: flex;
    justify-content: center;
    height: fit-content;
    align-items: center;
}

.centerPic80 {
    position: absolute;
    width: 80%;
    height: auto;
    top: 67%;
    left: 52%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.centerPic {
    position: absolute;
    width: 100%;
    height: auto;
    top: 58%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.drawShareCollabo {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 4rem;
    line-height: 100%;
    text-align: center;
    color: #49485d;
}

.newhelpBottom {
    margin: 'auto';
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100% !important;
    margin: auto !important;
    display: flex !important;
    justify-content: center !important;
}
.multiDevice {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    color: #49485d;
    width: 60%;
}


.startDrawingButton {
    display: inline-flex;
    cursor: pointer;
    padding: 16px 4vw;
    /* Periwinkle Blue */
    background: #3174F5;
    border-radius: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    /* or 26px */
    text-align: center;
    color: #FFFFFF;
    /* Inside Auto Layout */
    align-self: center;
    margin: 1rem;
    z-index: 100;
}

.TeachMsg {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    color: #49485d;
}

.TeachSubMsg {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    opacity: 0.8;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #6b6b7d;
}


.copyright {
    display: inline-flex;
    align-self: center;
    
    /* Privaci White */
    color:  #FFFFFF;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    text-decoration-line: none;
}

.tsPageLeftSide{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    height: auto;
    left: 0;
    top: 50%;
    width: 50%;
    transform: translate(0%, -50%);
    margin: 45px;
}


@media (max-width: 600px) {
    .newbgOrn{
        top: 32% !important;
    }
    .inPartnerSection{
        bottom: 20px !important;
    }
    .playIconVid{
        margin: 10px 0px !important;
        cursor: pointer !important;
        width: 90px !important;
    }
    .startDrawingButton{
        margin: 15px !important;
    }
    .TeachMsg{
        font-size: 26px !important;
    }
    .startDrawingButtonLast{
        width: 100% !important;
        padding: 10px 0vw;
        font-size: 20px !important;
        margin: 2px;
    }
    .inPartnerSection{
        display: none !important;
    }
    .tsContainer{
        margin: auto !important;
        min-height: 280px !important;
    }
    .tsHeadingLbl{
        margin: 15px 0 !important;
    }
    .tsPageLeftSide{
        position: relative;
        margin: 80px 35px;
        width: 90%;
        transform: translate(0%, 0%);

    }

    .tsContainer1{
        margin: 20px !important;
        width: 90% !important;
    }
    .multiDevice{
        width: 100% !important;
        margin: 10px auto;
    }
    .privacyNew{
        width: 55% !important;
        margin: auto !important;
        position: relative !important;
        bottom: 0px;
        left: 0;
        right: 0;
    }
    .feedbackNew{
        width: 60% !important;
        margin: auto !important;
        position: relative !important;
        bottom: 0px;
        left: 0;
        right: 0;
    }
    .footFlex{
        flex-direction: column;
    }

    .playIconGrp{
        margin: 20px 0 !important;
        height: 100px !important;
    }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
    .newhelpBottom{
        left: 50% !important;
    }

    .multiDevice{
        width: 80% !important;
    }
    .newbgOrn{
        top: 35% !important;
    }
    .tsContainer{
        width: 50%;
        margin: auto;
    }
    .testimonialContent{
        font-size: 16px !important;
    }
    .tsName{
        font-size: 18px !important;
    }
    .tsOccupation{
        font-size: 12px !important;
    }
    .startDrawingButtonLast{
        /* width: 100% !important; */
        margin: 0px auto !important;
    }
    .inPartnerSection{
        display: none !important;
    }
    .tsHeadingLbl{
        margin: 20px 0 !important;
    }
    .copyright{
        margin-left: 5px;
    }
}   

.mobFont28{
    font-size: 28px;
}
.drawContent{
    position: absolute;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}  

.justifyFlexDirCol{
    justify-content: center;
    flex-direction: column;
}

.feedlink{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-decoration-line: underline !important;
    color: #3174F5 !important;
}
.watchVid{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    color: #3174F5;
    text-decoration: none;
}

.reasonToUse{
    margin: 20px 0 10px;
}

.rdBtnContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px auto;
}

.subContent{
    margin: 40px auto;
}

.testimonialContent{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #002029;
    padding: 20px;
    position: relative;
}

.tscHt{
    height: 190px !important;
    overflow: scroll !important;
}

.testimonialContent  .quoteIcon{
    position: absolute;
    left: 1rem;
    top: 0.9rem;
}
.testimonialContainer{
    top: 20px;
}
.tsContainer{
    /* margin-top: 20px; */
    width: 98%;
    min-height: 300px;
    height: calc( 100vh - 72vh );
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 32 41 / 8%);
    border-radius: 8px;
    position: relative;
}

.tsInfo{
    background: #3174F5;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.tsName{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

.tsOccupation{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    margin-top: 4px;
}


.tsContainer1{
    margin-top: 20px;
    width: 98%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 32, 41, 0.08);
    border-radius: 8px;
}

.tsInfo1{
    background: #3174F5;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}


.testimonialMain{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    width: 100%;
}

.newCenterPicmid{
    position: absolute;
    height: auto;
    left: 50%;
    top: 40px;
    transform: translate(-50%, -50%);
}

.mt-50{
    margin-top: 50px !important;
}

.popMenuLi li{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #49485d;
}

.tsHeading{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 120%;
    color: #002029;
    text-align: left;
}

.tsContent{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #002029;
    text-align: left;
}
.newtsbgOrn {
    position: absolute;
    height: auto;
    z-index: 0;
    width: 100%;
}

.mt-100{
    margin-top: 100px !important;
}

.pinkBg{
    background-color: #FDF5E9 !important;
}
.pinkBg .tsName{
color: #002029 !important;
}
.pinkBg .tsOccupation {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #002029;
    margin-top: 8px;
}
    

.p-25{
    padding: 25px;
}
.secondSectionlbl{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 120%;
    color: #002029;
}

.doublequote{
    position: relative;
    z-index: 1;   
    left: 10px;
    top: 10px;
}
.secondSectionTs{
    margin: 40px 0;
}

.bottomBoxHelp1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20vh;
    background: #3174F5;
}

.bottomBox1 {
    bottom: 0;
    left: 0;
    height: 30vh;
    background: #3174F5;
}
.skybluetsBg{
    background-image: url('/bottomBarBgSkyBlue.svg');
}
.orangetsBg{
    background-image: url('/bottomBarBgOrange.svg');
}
.purplebluetsBg{
    background-image: url('/bottomBarBgPurpleBlue.svg');
}

.purpletsBg{
    background-image: url('/bottomBarBgPurple.svg');
}

.lightBlue{
    background-image: url('/bottomBarBgSkyBlueLight.svg');
}
.tsContainer1 .tsName, .tsContainer1 .tsOccupation{
    color: #002029;
}
.mainBgTestimonial{
    background-image: url(/bg2testimonial.svg);
    background-size: 100% 100%;
}
.tsHeadingLbl{
    margin: 10px 0 10px;
}
.posRelative{
    position: relative !important;
}
.feedbackNew {
    position: fixed;
    bottom: 5px;
    right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
}

.privacyNew {
    position: fixed;
    bottom: 5px;
    left: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
}

.newFeedPrivacy{
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 9px auto;
}

.playIconGrp{
    margin: 5px 0;
    cursor: pointer;
    height: 120px;
}