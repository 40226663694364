.highLightRect {
    position: fixed;
    border-radius: 8px;
    /* border: 6px solid #003D7F;
    box-shadow: 0 0 10px 2px #003D7F; */
    border: 6px solid #f2530b;
    box-shadow: 0 0 10px 2px #f2530b;
    /* pointer-events: none; */
    /* border: 6px solid #ffa500;
    box-shadow: 0 0 6px 2px #ffa500; */
}

.highLightRectOrange {
    border: 6px solid #ffa500;
    box-shadow: 0 0 6px 2px #ffa500;
}

@keyframes fadeIn { 
  from { opacity: 0; } 
}

.animate-flicker {
    animation: fadeIn 700ms infinite alternate;
}
