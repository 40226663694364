.headWBChat {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    left: 48px;
    align-items : center !important;
    display: flex !important;
    justify-content: center !important;    /* identical to box height */
    letter-spacing: -0.01em;
    color: #5D5770;
}

.buttonsTop {
    display: flex;
    flex-direction: row;
    padding: 0px;
    position: absolute;
    width: 345px;
    height: 39px;
    right: 33px;
    top: 32px;
}

.ontop {
    z-index: 100;
}

.bgOrn {
    position: absolute;
    width: 80%;
    height: auto;
    left: 10%;
    top: 20%;
    z-index: 0;
}

.centerPic40 {
    position: absolute;
    width: 40%;
    height: auto;
    top: 40%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.centerPicmid {
    position: absolute;
    height: auto;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.ontopReasons {
    z-index: 100;
    position: absolute;
    top: 5vh;
    left: 0;
}

.bottomBoxHelp {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20vh;
    background: #3174F5;
}

.bottomBox {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30vh;
    background: #3174F5;
}
.webinar {
    display: inline-flex;
    cursor: pointer;
    padding: 10px;
    position: relative;
    /* Periwinkle Blue */
    color:#180F33;
    /* color: whitesmoke; */
    /* Yellow */
    /* background: #FFC62F; */
    /* Green */
    background: #7FB055DD;
    border-radius: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.55rem;
    line-height: 0.75rem;
    /* or 26px */
    text-align: center;
    /* Inside Auto Layout */
    align-self: center;
    margin: 8px 0px 0px 0px;
    z-index: 100;
}

.storageplans {
    display: inline-flex;
    cursor: pointer;
    padding: 10px;
    position: relative;
    /* Periwinkle Blue */
    color:#180F33;
    /* color: whitesmoke; */
    /* Yellow */
    background: #FFC62F;
    /* Green */
    /* background: #7FB055DD; */
    border-radius: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.55rem;
    line-height: 0.75rem;
    /* or 26px */
    text-align: center;
    /* Inside Auto Layout */
    align-self: center;
    margin: 8px 0px 0px;
    z-index: 100;
}

.AlertBanner {
    /* display: inline-flex; */
    display: none;
    /* cursor: pointer; */
    padding: 10px;
    position: relative;
    /* Periwinkle Blue */
    color:#180F33;
    /* color: whitesmoke; */
    /* Yellow */
    /* background: #FFC62F; */
    /* Red */
    background: #e8543f;
    border-radius: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.55rem;
    line-height: 0.75rem;
    /* or 26px */
    text-align: center;
    /* Inside Auto Layout */
    align-self: center;
    margin: 10px 10px 0px 10px;
    z-index: 100;
}

.boldText {
    font-size: medium;
    font-weight: bold;
}

.mob {
    font-size: 0.6rem;
    padding: 6px;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
}

.mobxs {
    font-size: 0.5rem;
    padding: 6px;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top: 30px;
}

.startDrawingButtonLast {
    display: inline-flex;
    cursor: pointer;
    padding: 12px 4vw;
    /* Periwinkle Blue */
    color: #3174F5;
    border-radius: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    /* or 26px */
    text-align: center;
    background: #FFFFFF;
    /* Inside Auto Layout */
    align-self: center;
    margin: 8px 0px;
    z-index: 100;
}

.headWBChatLast {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    font-size: 24px;
    /* identical to box height */
    letter-spacing: -0.01em;
    color: #ffffff;
}

.centerPic80 {
    position: absolute;
    width: 80%;
    height: auto;
    top: 65%;
    left: 52%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.centerPicDemo {
    position: absolute;
    width: 20%;
    height: auto;
    bottom: 0;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, 0);
}

.centerPic {
    position: absolute;
    width: 100%;
    height: auto;
    top: 55%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.w-h-full {
    height: 100% !important;
    width: 100% !important;
}

.drawShareCollabo {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 4rem;
    line-height: 100%;
    /* or 96px */
    text-align: center;
    letter-spacing: -0.05em;
    color: #5D5770;
    margin-top: 60px;
}

.drawShareCollaboMob {
    font-size: 1.7rem !important;
}

.helpBottom {
    margin: 'auto';
    position: absolute;
    bottom: 7vh;
    left: 40%;
    /* bring your own prefixes */
    transform: translate(-50%, 0);
}
.multiDevice {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 150%;
    /* or 33px */
    text-align: center;
    letter-spacing: -0.01em;
    color: #5D5770;
    width: 40%;
    margin: auto;
}

.hbHow {
    background: #FFFFFF;
    border-radius: 50px;
    /* Inside Auto Layout */
    flex: none;
    align-self: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #5D5770;
    margin: 8px 0px;
    display: flex;
    padding: 11px 16px;
}

.hbMU {
    /* Chart/Soft Blue T160 */
    background: #DFE8F9;
    border-radius: 50px;
    /* Inside Auto Layout */
    flex: none;
    align-self: center;
    /* margin: 8px 0px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.webinarButton {
    background: #DFE8F9;
    border-radius: 50px;
    /* Inside Auto Layout */
    flex: none;
    align-self: center;
    margin: 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.webinarButton:hover, .hbMU:hover {
    -webkit-filter: brightness(95%);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.buyCloudCreditsButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.startDrawingButton {
    display: inline-flex;
    cursor: pointer;
    padding: 16px 4vw;
    /* Periwinkle Blue */
    background: #3174F5;
    border-radius: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    /* or 26px */
    text-align: center;
    color: #FFFFFF;
    /* Inside Auto Layout */
    align-self: center;
    margin: 10px 0px;
    z-index: 100;
}

.joinWithCodeButton {
    display: inline-flex;
    cursor: pointer;
    padding: 11px 2vw;
    /* Periwinkle Blue */
    background: #3174F5;
    /* background: #b4b1af; */
    border-radius: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 100;
    font-size: 0.5rem;
    /* or 26px */
    text-align: center;
    color: #FFFFFF;
    /* Inside Auto Layout */
    align-self: center;
    z-index: 100;
}

.joinCodeInput {
    display: inline-flex;
    cursor: pointer;
    padding: 12px 2vw;
    width: 160px;
    /* border: 1px solid #eceff1; */
    border: 2px solid #3174F5;
    border-radius: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 100;
    font-size: 0.5rem;
    /* or 26px */
    text-align: center;
    color: #5D5770;
    /* Inside Auto Layout */
    align-self: center;
    margin: 10px;
    margin-left: 0px;
    margin-right: 0px;
    z-index: 100;
}
.joinCodeInput:focus {
    outline: 0;
}

.privacy {
    position: fixed;
    bottom: 5px;
    left: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
}
/* .aboutus {
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
} */
.feedback {
    position: fixed;
    bottom: 5px;
    right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
}

.morePages {
    position: absolute;
    bottom: 10px;
    right: 50vw;
}

.TeachMsg {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    /* or 96px */
    text-align: center;
    letter-spacing: -0.05em;
    color: #5D5770;
}

.TeachSubMsg {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 0.5rem;
    line-height: 150%;
    /* or 33px */
    text-align: center;
    letter-spacing: -0.01em;
    color: #5D5770;
}

.ontopTeach {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
}

.playVideo {
    display: inline-flex;
    flex-direction: row;
    padding: 0px;
    text-align: center;
    /* Periwinkle Blue */
    color: #3174F5;
    /* Inside Auto Layout */
    flex: none;
    order: 1;
    align-self: center;
    margin: 8px 0px;
}

.teachIpad {
    display: inline-flex;
    align-self: center;
    width: 61vw;
    z-index: 10;
    top: 20vh;
    left: 20vw;
    position: absolute;
}

.teachStudents {
    display: inline-flex;
    position: absolute;
    left: 6.5vw;
    top: 37vh;
    align-self: center;
    width: 11vw;
    z-index: 10;
}

.teachLines {
    display: inline-flex;
    align-self: center;
    position: absolute;
    left: 9vw;
    width: 70vw;
    z-index: 0;
}

.teachTeacher {
    display: inline-flex;
    position: absolute;
    left: 75vw;
    top: 35vh;
    align-self: center;
    width: 20vw;
    z-index: 10;
}

.copyright {
    display: inline-flex;
    align-self: center;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    text-align: right;
    text-decoration-line: underline;
    /* Privaci White */
    color:  #FFFFFF;
}

.listText{
    padding: 5px !important;
    color: #5D5770 !important;
    align-items : center !important;
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
}

.AboutUsTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    /* or 96px */
    text-align: center;
    letter-spacing: -0.05em;
    color: #5D5770;
    margin-top: 50px;
}

.AboutUsPara {
    margin-top: 40px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-size: 0.8rem;
    line-height: 120%;
    color: #5D5770;
}

.PrivacyTitle {
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    /* or 96px */
    text-align: center;
    letter-spacing: -0.05em;
    color: #5D5770;
}

.CoppaUpdated {
    margin-bottom: 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 200;
    font-size: 0.7rem;
    line-height: 100%;
    /* or 96px */
    text-align: center;
    letter-spacing: -0.05em;
    color: #5D577088;
}


.PrivacyPoint {
    /* margin-top: 40px; */
    /* text-align: center; */
    font-family: Poppins;
    font-style: normal;
    font-size: 0.7rem;
    line-height: 100%;
    color: #5D5770;
}

.PrivacyPara {
    margin-top: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: Poppins;
    font-style: normal;
    font-size: 0.7rem;
    line-height: 140%;
    color: #5D5770;
}

.PrivacyParaTitle {
    margin-top: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: Poppins;
    font-style: normal;
    font-size: 1rem;
    line-height: 120%;
    color: #5D5770;
}

.PrivacyTemplate {
    margin-top: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.TosTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    /* or 96px */
    text-align: center;
    letter-spacing: -0.05em;
    color: #5D5770;
    margin-top: 50px;
}

.TosText {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    word-wrap: normal;
}

@media (max-width: 600px) {
    .listText{
        padding: 0px !important;
        color: #5D5770 !important;
        align-items : center !important;
        display: flex !important;
        justify-content: center !important;
        text-align: center !important;
        font-size: 0.5rem;
    }
    .bannerContainer{
        display: block !important;
        text-align: center;
        margin-bottom: 50px;
        position: absolute !important;
        top: 45px !important;
    }
  
    .multiDevice{
        width: 70% !important;
        margin: 10px auto;
        font-size: 0.7rem;
    }
    .bgOrn{
        top: 32% !important;
    }
    .storageplans{
        margin-left: 25px;
        margin-bottom: 10px;
    }
    .mobxs{
        margin-top: 0 !important;
    }
    .popupHeader{
        width: 80vw !important ;
    }
    .playButton img{
        height: 30px !important;
    }
    .newlogoImg{
        width: 200px;
        height: 50px;
    }
    
    .headerToolBar{
        padding: 0 !important;
        margin: 0 0 0 10px !important;
    }
    .footerDiv{
        position: absolute;
        top: 10px;
    }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
    .helpBottom{
        left: 50%;
    }

    .bannerContainer{
        display: block !important;
        text-align: center;
        position: relative;
    }
    .multiDevice{
        margin: 6px auto;
        width: 70% !important;
    }
    .bgOrn{
        top: 35% !important;
    }
     .bannerContainer{
        display: block !important;
        position: relative !important;
        top: 0 !important;
        margin: auto;
    }
    .drawShareCollaboMob{
        margin: 10px;
    }
    .storageplans{
        margin-left: 30px;
    }
    .popupHeader{
        width: 70vw !important ;
    }
    .footerDiv{
        position: absolute;
        top: 10px;
    }
}


@media only screen and (max-width: 1200px) and (min-width: 800px) {
    .multiDevice{
        width: 60% !important;
    }
    
    .bannerContainer{
        display: block !important;
        position: relative !important;
        width: 60% !important;
        top: 0 !important;
        margin: auto;
    }
}


.bannerContainer{
display: flex;
align-items: center;
justify-content: center;

position: absolute;
left: 0;
width: 100%;
top: 12vh;
}


.playButton {
    line-height: 150%;
    /* or 33px */
    text-align: center;
    letter-spacing: -0.01em;
}
.showMeText {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 150%;
    /* or 33px */
    text-align: center;
    /* letter-spacing: -0.01em; */
    color: #5D5770;
    padding-bottom: 6px;
    margin-left: 10px;
}
.playPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    }
   

.reasonslbl{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 22px !important;
    line-height: 140% !important;
    text-align: center !important;
    color: #49485D !important;
}

.reasonsList .listText{
    margin: 5px 0;
}

.anchorLblColor{
    color: #3174F5 !important;
}
.inPartnerSection{
    position: fixed;
    bottom: 15px;
    width: 100%;
}

.headerToolBar{
    margin: 0 20px;
}