.participantsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    width:635px;
}
.headerName .MuiTypography-h6 {
    color:#1E2639; 
    font-size: 20px;
}
.closeIcon {
    color:black;
    background-color: #D3D9DE ;
    border-radius: 999px;
    margin:0px 20px;
    width:15.5px;
    height:15.5px;
}
.lockSection {
    display: flex;
    flex-direction: column;
}
.classLockSection {
    display: flex;
    align-items: center;
    position:relative;
    margin:8px 0px;
}
.pageLockSection {
    display: flex;
    align-items: center;
    position:relative;
    margin:8px 0px;
}
.classLockSection .MuiSwitch-root {
    position: absolute;
    right: 0;
}
.pageLockSection .MuiSwitch-root {
    position: absolute;
    right: 0;
}
.pageLockSection .MuiTypography-body1, .classLockSection .MuiTypography-body1{
    font-size: 16px;
    font-family: 'Lato';
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: #1E2639;
}
.pageLockSection .MuiSvgIcon-root {
    color:#3174F5;
}
.classLockSection  .MuiSvgIcon-root {
    color:#3174F5;
}
.listHeading {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #6B7280;
}
.listItem{
    width:520px;
}
.listItem .MuiListItem-gutters{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.participantFooter{
    /* position: static; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;
    font-family: Lato;
    height: fit-content;
    font-style: normal;
    width: 580px;
}
.ownerName, .ownerId {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #2F3D5B;
    width: 275px;
    padding: 2px 0px;
    margin:0px;
}
.loginTime {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    padding: 3px 0px;
    color: #6B7280;
    width: 275px;
    margin:0px;
}
.listItem .MuiListItemText-primary{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #2F3D5B;
}
.listItem .MuiListItemText-secondary{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    padding: 4px 0px;
    color: #6B7280;
}
.listItem .MuiTypography-body2{
    font-size: 14px;
    line-height: 1.4;
}
.styling {
    margin-top: 20px;
    width: 520px;
    /* padding-left: 18px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.formGroup {
    margin-bottom: 10px;
}
.gridview {
    margin-left: -10px;
}
.gridview .MuiTypography-displayBlock {
    font-size: 14px;
}
.gridview .PrivateSwitchBase-root-1 {
    padding:2px;
}
.gridview .MuiListItemIcon-root {
    min-width: 40px
}
.groupList .MuiListSubheader-sticky {
    font-size: 16px;
}
.groupList .MuiTypography-displayBlock {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #2F3D5B;
    padding:4px 0px;
}
.groupList .MuiIconButton-edgeEnd {
    color: #3174F5;
    margin-left: 8px;
    border-radius: 0;
    padding: 5px;
}
.groupList .MuiListSubheader-gutters {
    padding: 0px;
    text-transform: uppercase;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.08em;
    color: #6B7280;
}
.groupList .MuiListItem-gutters {
    padding-left: 0px;
    padding-right: 0px;
}
.dialogModal .MuiDialog-paperWidthSm {
    max-width: 500px !important
}
.participantsDialog .MuiDialog-paperWidthSm {
    max-width: 650px !important
}
.avatarFooter{
    display:flex;
    width: 100%;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
}